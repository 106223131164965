import NextLink from 'next/link';
import {
    Stack,
    Container,
    Box,
    Flex,
    Text,
    Heading,
    SimpleGrid,
    Link,
} from '@chakra-ui/react';
import Icon from '@mdi/react';
import { mdiApple, mdiAndroid, mdiMonitor } from '@mdi/js';

export default function Device() {
    return (
        <Box
            id="create"
            bg={'gray.600'}
            py={32}
            textAlign="center"
            // bgGradient={'linear(to-l, purple.50 3%, gray.100)'}
        >
            <Heading as="h2" fontSize="4xl" textDecoration="none" color="white">
                TakeActive für jede Plattform
            </Heading>

            <Flex
                mt={[4, 4, 8]}
                mx="auto"
                maxW="xl"
                justifyContent={'space-between'}
                align="center"
            >
                <Box>
                    <Link
                        href="/login"
                        title=""
                        _hover={{
                            opacity: 0.5,
                        }}
                        ml={3}
                        w="48"
                        color="brand.blue_light"
                    >
                        <Icon path={mdiMonitor} size={5} />
                        <Text fontWeight="bold">WEB</Text>
                    </Link>
                </Box>

                <Box color="#9f9f9e">
                    <Link
                        href="https://play.google.com/store/apps/details?id=com.takeactive"
                        target="_blank"
                        title="Google Play"
                        _hover={{
                            opacity: 0.5,
                        }}
                        ml={3}
                        style={{ width: '13.5rem' }}
                    >
                        <Icon path={mdiAndroid} size={5} />
                        <Text fontWeight="bold">iOS</Text>
                    </Link>
                </Box>

                <Box color="#32de84">
                    <Link
                        href="https://apps.apple.com/us/app/id1523147697"
                        target="_blank"
                        title="App Store"
                        _hover={{
                            opacity: 0.5,
                        }}
                        ml={3}
                        w="48"
                    >
                        <Icon path={mdiApple} size={5} />
                        <Text fontWeight="bold">android</Text>
                    </Link>
                </Box>
            </Flex>
        </Box>
    );
}
