import { useAuthContext } from '@/components/context/authContext';
import Benefits from '@/components/subpages/Benefits';
import Layout from '@/components/ui/Layout';
import {
    Box,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Link,
    Text,
    Button,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import GooglePlayStore from '../components/images/GooglePlayStore';
import IosAppStore from '../components/images/IosAppStore';
import Phone from '../components/images/Phone';
import ActiveLink from '@/components/ActiveLink';
import Icon from '@mdi/react';
import {
    mdiArrowTopRightThick,
    mdiAccountPlus,
    mdiLogoutVariant,
    mdiMagnify,
} from '@mdi/js';
import SplitWithImage from '@/components/ui/SplitWithImage';
import Device from '@/components/ui/anbieter/Device';

export default function Index() {
    const router = useRouter();
    let { isAuthenticated } = useAuthContext();

    // if (isAuthenticated()) {
    //     useEffect(() => {
    //         router.push('/dashboard')
    //     }, [])
    // }

    return (
        <Layout
            title="Terminbuchung leicht gemacht"
            isIndex={true}
            isRaw={true}
            checkUserAuth={false}
            onlyForGuest={false}
            onlyForAuthenticatedUser={false}
        >
            <Box
                bg="white"
                bgGradient={[null, 'linear(to-r, white 55%, orange.100)']}
            >
                <Container maxW="7xl" my="6">
                    <Grid
                        templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
                        gap={[0, 6]}
                    >
                        <GridItem colSpan={2}>
                            <Heading
                                size="2xl"
                                color="black"
                                textDecoration="none"
                                fontWeight="black"
                                mt={[0, 4, 16, 24]}
                                letterSpacing="tight"
                            >
                                Einfache Terminbuchung
                                <br /> mit{' '}
                                <Text as="span" color="brand.red">
                                    TakeActive
                                </Text>
                            </Heading>
                            <Text color="gray.500" fontWeight="bold">
                                Mit der TakeActive App siehst du auf einem
                                Blick, welche Termine verfügbar sind. Diese
                                können bequem Online gebucht werden und bei
                                Änderungen wirst du sofort benachrichtigt.
                            </Text>

                            {isAuthenticated() && (
                                <ActiveLink
                                    href="/dashboard"
                                    activeClassName="active"
                                    includePath={false}
                                >
                                    <Button
                                        width={['full', 'full', 'initial']}
                                        size="lg"
                                        rightIcon={
                                            <Icon
                                                path={mdiArrowTopRightThick}
                                                size={1}
                                            />
                                        }
                                        bg="brand.blue"
                                        border="2px"
                                        boxShadow="2xl"
                                        _hover={{
                                            bg: 'gray.100',
                                            borderColor: 'gray.400',
                                        }}
                                        colorScheme="whiteAlpha"
                                        variant="solid"
                                        as="a"
                                        cursor="pointer"
                                        className="btn-header"
                                        mt={[2, 4, 8]}
                                        ml={[2, 2, 12]}
                                        p={[6, 6, 8]}
                                    >
                                        Zum Dashboard
                                    </Button>
                                </ActiveLink>
                            )}
                            {!isAuthenticated() && (
                                <ActiveLink
                                    href="/registrieren"
                                    activeClassName="active"
                                    includePath={false}
                                >
                                    <Button
                                        width={['full', 'full', 'initial']}
                                        size="lg"
                                        rightIcon={
                                            <Icon
                                                path={mdiArrowTopRightThick}
                                                size={1}
                                            />
                                        }
                                        bg="brand.blue"
                                        border="2px"
                                        boxShadow="2xl"
                                        _hover={{
                                            bg: 'gray.100',
                                            borderColor: 'gray.400',
                                        }}
                                        colorScheme="whiteAlpha"
                                        variant="solid"
                                        as="a"
                                        cursor="pointer"
                                        className="btn-header"
                                        mt={[2, 4, 8]}
                                        ml={[2, 2, 12]}
                                        p={[6, 6, 8]}
                                    >
                                        Jetzt Registrieren
                                    </Button>
                                </ActiveLink>
                            )}

                            {/* <ActiveLink
                                    href="/login"
                                    activeClassName="active"
                                    includePath={false}
                                >
                                    <Button
                                        width={['full', 'full', 'initial']}
                                        leftIcon={
                                            <Icon
                                                path={mdiArrowTopRightThick}
                                                size={1}
                                            />
                                        }
                                        bg="gray.500"
                                        variant="solid"
                                        as="a"
                                        cursor="pointer"
                                        className="btn-header"
                                    >
                                        Login
                                    </Button>
                                </ActiveLink> */}
                            {/* <Link
                                    href="https://apps.apple.com/us/app/id1523147697"
                                    target="_blank"
                                    title="App Store"
                                    _hover={{
                                        opacity: 0.5,
                                    }}
                                    ml={3}
                                    w="48"
                                >
                                    <IosAppStore />
                                </Link>
                                <Link
                                    href="https://play.google.com/store/apps/details?id=com.takeactive"
                                    target="_blank"
                                    title="Google Play"
                                    _hover={{
                                        opacity: 0.5,
                                    }}
                                    ml={3}
                                    style={{ width: '13.5rem' }}
                                >
                                    <GooglePlayStore />
                                </Link> */}
                            {/* </Flex> */}
                        </GridItem>

                        <Box w={[36, 36, 44, 56]} py="8" mx="auto">
                            <Phone />
                        </Box>
                    </Grid>
                </Container>
            </Box>
            <Box className="maximumheight" bg="gray.600">
                <Container maxW="7xl" my="16">
                    <Heading
                        textAlign="center"
                        color="gray.300"
                        textDecoration="none"
                        mt="2"
                        mb="8"
                    >
                        So kannst du die App nutzen
                    </Heading>

                    {Benefits.map(item => (
                        <SplitWithImage key={item.id} item={item} />
                    ))}
                    {/* <Flex
                        color="gray.400"
                        flexDirection={['column', 'column', 'row']}
                    >
                        {Benefits.map(item => benefitBox(item))}
                    </Flex> */}
                </Container>
            </Box>
            {/* <Device /> */}
        </Layout>
    );
}

// function benefitBox(item) {
//     return (
//         <Box flex={1} p="8" key={item.id}>
//             <Flex alignItems="center">
//                 <Center
//                     bg="brand.blue"
//                     color="white"
//                     h="12"
//                     minW="12"
//                     borderRadius="md"
//                     mr="2"
//                 >
//                     {item.icon}
//                 </Center>
//                 <Text as="div" fontWeight="bold" color="gray.100">
//                     {item.heading}
//                 </Text>
//             </Flex>

//             <Text>{item.text}</Text>
//         </Box>
//     )
// }
