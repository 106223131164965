import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
    Box,
} from '@chakra-ui/react';
// import { IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from 'react-icons/io5'
// import { ReactElement } from 'react'

const Feature = ({ text, icon, iconBg }) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}
            >
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

export default function SplitWithImage({ item }) {
    return (
        <Container maxW={'5xl'} py={12}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Flex flexDirection="column" justify="center" wrap="wrap">
                    <Text
                        width="fit-content"
                        textTransform={'uppercase'}
                        // color={'blue.400'}
                        color={'brand.blue'}
                        fontWeight={600}
                        fontSize={'sm'}
                        bg={useColorModeValue('blue.50', 'blue.900')}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}
                    >
                        Schritt {item.id}
                    </Text>
                    <Box>
                        {/* <Text as="div" fontWeight="bold" color="gray.100">
                        {item.heading}
                    </Text> */}
                        <Heading color="orange.100" fontSize={'2xl'}>
                            {item.heading}
                        </Heading>
                        <Text color={'gray.300'} fontSize={'lg'}>
                            {item.text}
                        </Text>
                    </Box>
                </Flex>
                <Flex>
                    <Image
                        rounded={'xl'}
                        alt={'feature image'}
                        // src={
                        //     'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        // }
                        src={`/images/frontpage_steps/${item.id}_450.png`}
                        objectFit={'cover'}
                    />
                </Flex>
            </SimpleGrid>
        </Container>
    );
}
